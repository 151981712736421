import React, { Component } from 'react'


export default class Init extends Component {
    render() {
        return (
            <main className="content">
            <div className="jumbotron jumbotron-fluid">

                    <div className="container bienvenida">

                        <h1 className="display-4">BIENVENIDO A SGI</h1>

                        <p className="lead">Sistema de Gestión para Importaciones</p>

                    </div>
                    </div>
            </main>
        )
    }
}
