import React, { Component } from 'react'


export default class Init extends Component {
    render() {
        return (
            <footer className="footer">
                    <div className="text-center">
                        <h5>Copyright© SGI - Proma Chile {new Date().getFullYear()}</h5>
                    </div>
            </footer>
        )
    }
}
